import React, { useCallback, useMemo, useState } from "react"
import {
  Box,
  FormField,
  TextInput,
  Form,
  MaskedInput,
  RadioButtonGroup, CheckBox, Heading, Text, Button, ResponsiveContext, Paragraph, Spinner,
} from "grommet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useFormik } from "formik"
import Image from "../components/image"
import { MiBusLogo } from "../components/miBusLogo"
import { StatusGood } from "grommet-icons"
import RoutedButton from "../components/routedButton"
import { phoneMask } from "../helpers/constants"
import * as Yup from "yup"
import Link from "../components/link"
import { buy, checkoutProgress } from "../helpers/analytics"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Полето е задължително").max(255, "Моля въведете по-късо име"),
  address: Yup.string().required("Полето е задължително").max(255, "Моля въведете по-къс адрес"),
  city: Yup.string().required("Полето е задължително").max(255),
  email: Yup.string().email("Моля въведете валиден имейл").required("Полето е задължително"),
  phone_number: Yup.string().required("Полето е задължително"),
  device_count: Yup.number().min(0, "Трябва да закупите поне 1").required("Полето е задължително"),
  company_name: Yup.string()
    .when("invoice", {
      is: (invoice) => !!invoice,
      then: Yup.string().required("Полето е задължително").max(255, "Моля въведете по-късо име"),
      otherwise: Yup.string(),
    }),
  vat: Yup.string()
    .when("invoice", {
      is: (invoice) => !!invoice,
      then: Yup.string().required("Полето е задължително").max(12, "Невалиден ДДС номер"),
      otherwise: Yup.string(),
    }),
  invoice: Yup.bool(),
})

const DEVICE_PRICE = 89.99;

const OrderPage = () => {
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, errors, handleChange, setFieldValue, submitForm, status, handleBlur } = useFormik({
    initialValues: {
      company_name: "",
      vat: "",
      name: "",
      invoice: false,
      address: "",
      city: "",
      phone_number: "+(359) ",
      email: "",
      deliver_to: "address",
      payment_method: "on_delivery",
      courier: "Econt",
      device_count: 1,
      country: 'България'
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      setLoading(true);
      try {
        await fetch(`${process.env.GATSBY_AUTH_API_URL}/place-order`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
        setCompleted(true)
      } catch (e) {
        actions.setStatus('error');
      } finally {
        setLoading(false);
      }
    },
  })
  const handleBlurEvent = useCallback((e) => {handleBlur(e); checkoutProgress(values);}, [values, handleBlur]);
  const deliveryPrice = useMemo(() => (5.4 + values.device_count * 0.5 + values.device_count * DEVICE_PRICE * 0.0242), [values.device_count])
  return (
    <Layout>
      <ResponsiveContext.Consumer>
        {size => (
          <React.Fragment>
            <Seo title="Order" />
            {completed ? <Box pad="large" align="center">
                <Box direction="row" gap="small">
                  <StatusGood size="xlarge" color="status-ok" /><Heading>Вашата поръчка е записана успешно!</Heading>
                </Box>
                <Paragraph>
                  докато чакате вашето mi<b>Bus</b> устройство, може да се регистрирате в <Link to={process.env.GATSBY_DASHBOARD_URL}>платформата за управление</Link> на <b>mitabits</b>.
                  там има подробна информация за начина на инсталиране и конфигуриране на mi<b>Bus</b>.
                </Paragraph>
                <Box direction="row-responsive" gap="small" margin={{ top: "small" }}>
                  <RoutedButton primary label="Към платформата" to={process.env.GATSBY_DASHBOARD_URL} />
                  <RoutedButton label="Назад" secondary to="/" />
                </Box>
              </Box> :
              <Form>
                <Box>
                  <Heading alignSelf="center">Поръчка</Heading>
                  <Box pad={{ vertical: "large"}}>
                    <Box direction="row-responsive"
                         justify="center"
                         align="start"
                         gap="small"
                         pad={{horizontal: size === 'small' || size === 'xsmall' ? 'medium' : 'none' }}
                    >
                      <Box gap="medium">
                        <Text weight="bold" size="large" margin={{ left: "small" }}>Лични данни</Text>
                        <Box>
                          <FormField label="Имена" error={errors.name}>
                            <TextInput
                              name="name"
                              onBlur={handleBlurEvent}
                              value={values.name}
                              onChange={handleChange}
                            />
                          </FormField>
                          <Box direction="row-responsive" gap="small">
                            <FormField label="Email" error={errors.email}>
                              <TextInput
                                name="email"
                                onBlur={handleBlurEvent}
                                value={values.email}
                                onChange={handleChange}
                              />
                            </FormField>
                            <FormField label="Телефонен номер" error={errors.phone_number}>
                              <MaskedInput
                                mask={phoneMask}
                                name="phone_number"
                                onBlur={handleBlurEvent}
                                value={values.phone_number}
                                onChange={handleChange}
                              />
                            </FormField>
                          </Box>
                        </Box>
                        <Box>
                          <Box pad={{ left: "small", bottom: "medium" }}>
                            <CheckBox
                              checked={values.invoice}
                              label="Фактура"
                              onChange={(event) => setFieldValue("invoice", event.target.checked)}
                            />
                          </Box>
                          {values.invoice && <Box gap="medium">
                            <Text weight="bold" size="large" margin={{ left: "small" }}>Данни за фактура</Text>
                            <Box direction="row-responsive" gap="small">
                              <FormField label="Име на фирмата" error={errors.company_name}>
                                <TextInput
                                  name="company_name"
                                  onBlur={handleBlurEvent}
                                  value={values.company_name}
                                  onChange={handleChange}
                                />
                              </FormField>
                              <FormField label="Номер по ДДС" error={errors.vat}>
                                <TextInput
                                  name="vat"
                                  onBlur={handleBlurEvent}
                                  value={values.vat}
                                  onChange={handleChange}
                                />
                              </FormField>
                            </Box></Box>}
                        </Box>
                      </Box>

                      <Box gap="medium">
                        <Text weight="bold" size="large" margin={{ left: "small" }}>Данни за доставка</Text>
                        <Box>
                          <FormField label={values.deliver_to === "address" ? "Адрес" : "Име на офис"} error={errors.address}>
                            <TextInput
                              name="address"
                              onBlur={handleBlurEvent}
                              value={values.address}
                              onChange={handleChange}
                            />
                          </FormField>
                          <Box direction="row-responsive" gap="small">
                            <FormField label="Град" error={errors.city}>
                              <TextInput
                                name="city"
                                onBlur={handleBlurEvent}
                                value={values.city}
                                onChange={handleChange}
                              />
                            </FormField>
                            <FormField label="Държава">
                              <TextInput
                                name="country"
                                onBlur={handleBlurEvent}
                                disabled
                                value={values.country}
                                onChange={handleChange}
                              />
                            </FormField>
                          </Box>
                          <Box direction="row-responsive" fill>
                            <Box direction="row-responsive">
                              <FormField label="Достави до" error={errors.deliver_to}>
                                <RadioButtonGroup
                                  name="deliver_to"
                                  options={[{ label: "Адрес", value: "address" }, { label: "Офис", value: "office" }]}
                                  value={values.deliver_to}
                                  onChange={handleChange}
                                />
                              </FormField>
                              <FormField label="Куриер" error={errors.courier}>
                                <RadioButtonGroup
                                  name="courier"
                                  options={["Econt", "Speedy"]}
                                  value={values.courier}
                                  onChange={handleChange}
                                />
                              </FormField>
                            </Box>
                            <FormField label="Начин на плащане" error={errors.payment_method}>
                              <RadioButtonGroup
                                name="payment_method"
                                options={[{ label: "Наложен платеж", value: "on_delivery" }, {
                                  label: "С карта",
                                  value: "by_card",
                                  disabled: true,
                                }]}
                                value={values.payment_method}
                                onChange={handleChange}
                              />
                            </FormField>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box alignSelf='center' width='large'><Image /></Box>
                    <Box pad={{vertical: 'large', horizontal: size}} direction="row" align="center" justify="center" gap={size}>
                      <MiBusLogo size={size} beta={size !== 'small' && size !== 'xsmall'} />
                      <Text size={size}>x</Text>
                      <FormField label="Брой устройства" error={errors.device_count}>
                        <TextInput
                          name="device_count"
                          onBlur={handleBlurEvent}
                          type="number"
                          min={1}
                          max={5}
                          value={values.device_count}
                          onChange={handleChange}
                        />
                      </FormField>
                      <Text size={size}>=</Text>
                      <Box>
                        <Box gap="small">
                          <Text weight="bold" size={size === 'small' || size ==='xsmall' ? 'medium' : 'xxlarge'}>{(values.device_count * DEVICE_PRICE).toFixed(2)} лв</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {values.device_count ?
                    <Box alignSelf="center" gap="small">
                      <Text size="large">Доставка: {deliveryPrice.toFixed(1)} лв</Text>
                      <Text size="large">Тотал: <b>{(deliveryPrice + values.device_count * DEVICE_PRICE).toFixed(2)} лв</b></Text>
                    </Box>
                    : null}
                  {loading ? <Box align='center' margin={{ top: "large"}}><Spinner/></Box> : <Button type="submit" label="Поръчай" primary size="large" alignSelf="center"
                                                                                                     margin={{ top: "large", bottom: "small" }} onClick={() => {buy(values); submitForm()}} />}
                  {status && <Box align='center' fill pad='medium'>
                    <Text color='status-error' weight='bold'>Нещо се обърка</Text>
                    <Paragraph>Моля <Link to='/contact-us'>свържете се с нас</Link>, чрез някой от изброените методи на страницата ни за контакти.</Paragraph>
                  </Box>}

                </Box>
              </Form>}
          </React.Fragment>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}

export default OrderPage
